// src/components/Search.js
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const Search = ({ chapters }) => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  const handleSearch = () => {
    const results = [];
    const query = searchQuery.toLowerCase().trim();

    // Iterate through chapters and texts to find matches
    chapters.forEach((chapter) => {
      chapter.texts.forEach((text) => {
        if (
          text.sloka.toLowerCase().includes(query) ||
          text.meaning.toLowerCase().includes(query) ||
          text.wordtoword.toLowerCase().includes(query)
        ) {
          results.push({ chapter, text });
        }
      });
    });

    setSearchResults(results);
  };

  const handleResultClick = (chapterNumber, slokaNumber) => {
    navigate(`/sloka/${chapterNumber}/${slokaNumber}`);
  };
  const handleClearSearch = () => {
    setSearchQuery('');
    setSearchResults([]);
  };

  return (
    <div className="container search">
      <h1>Search</h1>
      <div className="search-bar">
        <input
          type="text"
          placeholder="Enter your search..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <button onClick={handleSearch}>Search</button>
        <button onClick={handleClearSearch}>Clear Search</button>
      </div>
      <div className="search-results">
        {searchResults.map(({ chapter, text }) => (
          <div
            key={`${chapter.number}-${text.number}`}
            className="search-result"
            onClick={() => handleResultClick(chapter.number, text.number)}
          >
            <h3>{chapter.title}</h3>
            <p className="sloka-title">శ్లోకం {text.title}</p>
            <p className="meaning">{text.meaning}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Search;
