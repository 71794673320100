// src/components/Home.js
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import data from '../data.json';

const Book = () => {
  const navigate = useNavigate();
  const [chapters, setChapters] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedBook, setSelectedbook] = useState();
  const { bookNumber } = useParams();

  useEffect(() => {
    const currentBook = data.books.find((book) => book.id === Number(bookNumber));
    const chaptersByBook = currentBook.chapters;
    setSelectedbook(currentBook);
    setChapters(chaptersByBook);
  }, []);

  const handleSearch = () => {
    const results = [];
    chapters.forEach((chapter) => {
      chapter.texts.forEach((text) => {
        const searchString = `${chapter.number}.${text.number} ${text.sloka} ${text.meaning} ${text.wordtoword}`;
        if (searchString.toLowerCase().includes(searchTerm.toLowerCase())) {
          results.push({ chapter, text });
        }
      });
    });
    setSearchResults(results);
  };

  const handleResultClick = (chapterNumber, slokaNumber) => {
    navigate(`/sloka/${bookNumber}/${chapterNumber}/${slokaNumber}`);
  };
  const handleClearSearch = () => {
    setSearchTerm('');
    setSearchResults([]);
  };
  return (
    <div className="container home">


      <div className="search-bar">
        <input
          type="text"
          placeholder="Search by word or chapter + text number (e.g., 2.15)"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <div className="button-container">
          <button onClick={handleSearch}>Search</button>
          <button onClick={handleClearSearch}>Clear Search</button>
        </div>
      </div>

      {searchResults.length > 0 && (
        <div className="search-results">
          <h3>Search Results</h3>
          {searchResults.map((result) => (
            <div
              key={`${result.chapter.number}-${result.text.number}`}
              className="search-result"
              onClick={() => handleResultClick(result.chapter.number, result.text.number)}
            >
              <div>{`${result.chapter.number}.${result.text.number}`}</div>
              <div>{result.chapter.title}</div>
              <div>{result.text.sloka}</div>
            </div>
          ))}
        </div>
      )}
      <h1 className="book-title">{selectedBook?.name}</h1>
      <h3 className="main-title">అధ్యాయాలు</h3>
      <div className="chapter-list">
        {chapters.map((chapter) => (
          <Link key={chapter.number} to={`/chapter/${bookNumber}/${chapter.number}`} className="chapter-title">
            <div>{chapter.number}</div>
            <div>{chapter.title}</div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Book;
