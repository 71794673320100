// src/App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Chapter from './components/Chapter';
import SlokaDetails from './components/SlokaDetails'; // New component
import Search from './components/Search';
import Header from './components/Header';
import Footer from './components/Footer';
import Book from './components/Book';
import data from './data.json';
import './styles.css';
import Songs from './components/Songs';
import SongDetails from './components/SongDetails';

function App() {
  const [chaptersData, setChaptersData] = useState(data.chapters);

  return (
    <Router>
      <div className="container">
        <Header />
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/book/:bookNumber" element={<Book/>} />
          <Route path="/chapter/:bookNumber/:chapterNumber" element={<Chapter/>} />
          <Route path="/sloka/:bookNumber/:chapterNumber/:slokaNumber" element={<SlokaDetails/>} />
          <Route path="/search/:query" element={<Search chapters={chaptersData} />} />
          <Route path="/vaishanvasongs" element={<Songs />} /> {/* New route for songs */}
          <Route path="/vaishanvasongs/:songNumber" element={<SongDetails />} /> {/* New route for song details */}
          {/* <Route
            path="/add-text"
            element={<AddText chapters={chaptersData} onSave={saveText} />}
          /> */}
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
