// src/components/SongDetails.js
import React from 'react';
import songsData from '../songs.json'; // Same JSON file
import { FaHome } from 'react-icons/fa';
import { useParams, useNavigate,Link } from 'react-router-dom';

const SongDetails = () => {
    const navigate = useNavigate();
  const { songNumber } = useParams();
  const song = songsData.find(s => s.number === parseInt(songNumber));

  if (!song) {
    return <div className="song-not-found">Song not found.</div>;
  }

  return (
    <div className="song-details-container">
    <div className="navigation-links">
    <Link to="/">
      <FaHome size={20} className="home-link" />
    </Link>
    <span  className="chapter-link" onClick={() => navigate(`/vaishanvasongs`)}>
      {` >> వైష్ణవ గీతావళి << `}
    </span>
    
  </div>
      <h1 className="song-title">{song.title}</h1>
      <h2 className="song-source">{song.source}</h2>
      <pre className="song-details">{song.details}</pre>
    </div>
  );
};

export default SongDetails;
