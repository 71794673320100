// src/components/Chapter.js
import React, { useEffect, useState } from 'react';
import { useNavigate,useParams } from 'react-router-dom';
import data from '../data.json';

const Chapter = () => {
  const navigate = useNavigate();
  const { bookNumber, chapterNumber } = useParams();
  const [chapter, setChapter] = useState();
  
  useEffect(()=>{
    const selectedChapter = data.books.find((book)=> book.id === Number(bookNumber)).chapters.find((chapter)=> chapter.number === Number(chapterNumber));
    setChapter(selectedChapter);
  },[]);

  const handleSlokaClick = (slokaNumber) => {
    navigate(`/sloka/${bookNumber}/${chapterNumber}/${slokaNumber}`);
  };

  return (
    <div className="container chapter">
        <div key={chapter?.number}>
          <h1 className="chapter-title">{chapter?.title}</h1>
          {chapter?.texts.map((text) => (
            <div className="text-section clickable" key={text.number} onClick={() => handleSlokaClick(text.title)}>
              <h2 className="sloka-title">శ్లోకం {text.title}</h2>
              <div className="text">
                <div className="meaning">{text.meaning}</div>
              </div>
            </div>
          ))}
        </div>
    </div>
  );
};

export default Chapter;
