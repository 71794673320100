// src/components/Songs.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import songsData from '../songs.json'; // JSON file for songs

const Songs = () => {
  const [searchTerm, setSearchTerm] = useState('');

  const groupedSongs = songsData.reduce((acc, song) => {
    // Updated regex to match either after "1." or after ":"

    // Extract the first character found from either match group
    const firstCharacter =song.firstWord.charAt(0); // Default to 'Unknown' if no match

    if (!acc[firstCharacter]) {
        acc[firstCharacter] = [];
    }
    acc[firstCharacter].push(song);
    
    return acc;
}, {});

  // Filter songs based on search term
  const filteredSongs = Object.entries(groupedSongs).reduce((acc, [letter, songs]) => {
    const filtered = songs.filter(song =>
      song.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    if (filtered.length) {
      acc[letter] = filtered;
    }
    return acc;
  }, {});

  return (
    <div className="container">
      <h1>వైష్ణవ గీతావళి</h1>
      <input
        type="text"
        placeholder="Search songs..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{ marginBottom: '20px', width: '100%', padding: '10px' }}
      />
      <div className="song-list" style={{ display: 'flex', flexWrap: 'wrap' }}>
        {Object.keys(filteredSongs).map(letter => (
          <div key={letter} style={{ flex: '1 1 45%', margin: '10px' }}>
            <h2>{letter}</h2>
            <ul>
              {filteredSongs[letter].map(song => (
                <li key={song.number}>
                  <Link to={`/vaishanvasongs/${song.number}`}>{song.firstWord} : {song.title} </Link>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Songs;
