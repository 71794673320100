import React, { useCallback } from 'react';
import data from '../data.json';
import { useNavigate } from 'react-router-dom';

const Home = () => {
    const navigate = useNavigate();

    const handleBookClick = useCallback((bookId) => {
        if(bookId!=4)
         navigate(`/book/${bookId}`);
        else
         navigate(`/vaishanvasongs`);
    }, []);

    return (
        <div className='book-cards'>
            {data.books.map((book) => (
                <div className="book-card clickable" key={book.id} onClick={() => handleBookClick(book.id)}>
                    <img src={book.imgSrc} alt={book.name} />
                    <div className='img-content'>
                        <h4><b>{book.name}</b></h4>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default Home;