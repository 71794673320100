// src/components/Footer.js
import React from 'react';

const Footer = () => {
  return (
    <div className="footer">
      <img src="/chaitanya.png" alt="Chaitanya" className="footer-image" />
      <p>© 2024 వేదగమ్యం. All rights reserved.</p>
    </div>
  );
};

export default Footer;
