import React from 'react';
import { Link } from 'react-router-dom';
import { FaHome } from 'react-icons/fa'; // Import the home icon from Font Awesome

const Header = () => {
  return (
    <div className="header">

        <div className='header-item'>
          <Link to="/">
            <img src="/lordKrishna.jpg" alt="Logo" className="logo" />
          </Link>
        </div>

        <div className='header-item'>
          <Link to="/" className="home-icon">
            <FaHome size={30} />
          </Link>
          <h1 className="title">వేదగమ్యం</h1>
        </div>

        <div className='header-item'>
          <Link to="/">
            <img src="/prabhupad1.jpg" alt="Logo" className="logo" />
          </Link>
        </div>

      {/* <Link to="/">
        <img src="/prabhupad1.jpg" alt="Logo" className="logo" />
      </Link>
      <div className="logo-title-container">
        <Link to="/" className="home-icon">
          <FaHome size={30} />
        </Link>
        <h1 className="title">వేదగమ్యం</h1>
      </div> */}
    </div>
  );
};

export default Header;
