import React from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { FaHome, FaWhatsapp } from 'react-icons/fa';
import data from '../data.json';

const SlokaDetails = () => {
  const { bookNumber, chapterNumber, slokaNumber } = useParams();
  const navigate = useNavigate();

  // Find the book based on the bookNumber
  const book = data.books.find((book) => book.id === Number(bookNumber));
  if (!book) {
    return <div className="container">Book not found</div>;
  }

  // Find the chapter based on the chapterNumber
  const chapter = book.chapters.find((chapter) => chapter.number === Number(chapterNumber));
  if (!chapter) {
    return <div className="container">Chapter not found</div>;
  }

  const totalChapters = book.chapters.length;

  // Find the index of the selected sloka based on the title
  const selectedSlokaIndex = chapter.texts.findIndex((text) => text.title === slokaNumber);
  if (selectedSlokaIndex === -1) {
    return <div className="container">Sloka not found</div>;
  }
  // Get the selected sloka based on the index
  const selectedSloka = chapter.texts[selectedSlokaIndex];

  const previousSlokaTitle = selectedSlokaIndex > 0 ? chapter.texts[selectedSlokaIndex - 1].title : null;
  const nextSlokaTitle =
    selectedSlokaIndex < chapter.texts.length - 1 ? chapter.texts[selectedSlokaIndex + 1].title : null;

  const handlePrevious = () => {
    if (previousSlokaTitle) {
      navigate(`/sloka/${bookNumber}/${chapterNumber}/${previousSlokaTitle}`);
    } else {
      const previousChapterNumber = Number(chapterNumber) - 1;
      navigate(`/chapter/${bookNumber}/${previousChapterNumber}`);
    }
  };

  const handleNext = () => {
    if (nextSlokaTitle) {
      navigate(`/sloka/${bookNumber}/${chapterNumber}/${nextSlokaTitle}`);
    } else {
      const nextChapterNumber = Number(chapterNumber) + 1;
      if (nextChapterNumber <= 18) navigate(`/chapter/${bookNumber}/${nextChapterNumber}`);
    }
  };

  const handleShareWhatsApp = () => {
    const message = `అధ్యాయం - ${chapterNumber}  శ్లోకం ${selectedSloka.title}\n\n${selectedSloka.sloka}\n\n${selectedSloka.wordtoword}\n\n${selectedSloka.meaning}`;
    const encodedMessage = encodeURIComponent(message);
    window.open(`https://wa.me/?text=${encodedMessage}`, '_blank');
  };

  return (
    <div className="container sloka-details">
      <div className="navigation-links">
        <Link to="/">
          <FaHome size={20} className="home-link" />
        </Link>
        <span>
          {` >> ${book.name} >> `}
        </span>
        <span className="chapter-link" onClick={() => navigate(`/chapter/${bookNumber}/${chapterNumber}`)}>
          {` ${chapter.number} వ అధ్యాయం >>`}
        </span>
        <span className="">శ్లోకం {slokaNumber}</span>
      </div>
      <h1 className="chapter-title" onClick={() => navigate(`/chapter/${bookNumber}/${chapterNumber}`)}>
        {chapter.title}
      </h1>
      <h2 className="sloka-title">శ్లోకం {selectedSloka.title}</h2>
      <div className="text">
        <div className="sloka">{selectedSloka.sloka}</div>
        <div className="word-to-word">{selectedSloka.wordtoword}</div>
        <div className="meaning">{selectedSloka.meaning}</div>
      </div>
      <div className="navigation-buttons">
        {previousSlokaTitle && (
          <button className="previous-button" onClick={handlePrevious}>
            Previous Sloka
          </button>
        )}
        {!previousSlokaTitle && Number(chapterNumber) > 1 && (
          <button className="previous-button" onClick={handlePrevious}>
            Previous Chapter
          </button>
        )}
        {nextSlokaTitle && (
          <button className="next-button" onClick={handleNext}>
            Next Sloka
          </button>
        )}
        {!nextSlokaTitle && chapterNumber < totalChapters && (
          <button className="next-button" onClick={handleNext}>
            Next Chapter
          </button>
        )}

        {/* Share button for WhatsApp */}
        <button className="share-button" onClick={handleShareWhatsApp}>
          <FaWhatsapp size={20} />
        </button>
      </div>
    </div>
  );
};

export default SlokaDetails;
